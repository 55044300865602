<template>
  <div class="exception-tag" v-if="!isProcessing">
    <tag-menu class="exception-tag__menu" v-for="tag in tags" :key="tag.name" :tag="tag" />
    <v-btn class="exception-tag__add" @click="$refs.add.open()">
      <v-icon class="exception-tag__add__icon">add</v-icon>
    </v-btn>
    <tag-add ref="add" />
  </div>
</template>

<script>
import TagMenu from '@/components/exception/tag.vue'
import TagAdd from '@/components/exception/form/tag/add.vue'

export default {
  name: 'exception_tag',
  components: { TagMenu, TagAdd },
  async mounted () {
    const promises = []
    if (this.tags.length === 0) promises.push(this.$store.dispatch('tags/getTags'))
    if (this.exemptions.length === 0) promises.push(this.$store.dispatch('exemptions/getExemptions'))

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object[]} タグ一覧
     */
    tags () {
      return this.$store.getters['tags/tags']
    },
    /**
     * @return {Object[]} 例外情報一覧
     */
    exemptions () {
      return this.$store.getters['exemptions/exemptions']
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';
@import '@/assets/sass/fontfamily.scss';

.exception-tag {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height 0;
  margin: $header_height auto 0 auto;
  &__menu {
    margin-top: 10px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  &__add {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: $btn_zindex;
    border-radius: 50%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    &.v-btn:not(.v-btn--round).v-size--default {
      width: 50px;
      min-width: 50px;
      height: 50px;
      padding: 0;
    }
    &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $orange_color;
    }
    &__icon {
      font-family: $material-outlined;
      &.v-icon {
        font-size: 40px;
        color: $white_color;
      }
    }
  }
}
</style>
