<template>
  <v-dialog  content-class="edit" v-model="dialog">
    <v-card class="edit__area">
      <v-card-title class="edit__area__title">タグの変更</v-card-title>
      <v-card-text class="edit__area__msg">「{{ tag.name }}」の名前を変更します。</v-card-text>
      <input class="edit__area__input" placeholder="変更したいタグの名前" v-model="name" />
      <v-btn class="edit__area__btn" :disabled="!canEdit" depressed @click="edit()">
        変更する
      </v-btn>
      <v-btn text class="edit__area__close" @click="doClose()">閉じる</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    // タグ情報
    tag: {
      type: Object,
      requred: true
    }
  },
  data () {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      // 新しいタグ名
      name: ''
    }
  },
  computed: {
    /**
     * @return {Object[]} タグ情報一覧
     */
    tags () {
      return this.$store.getters['tags/tags']
    },
    /**
     * @return {Object[]} 例外情報一覧
     */
    exemptions () {
      return this.$store.getters['exemptions/exemptions']
    },
    /**
     * @return {Boolean} タグ名が変更可能かどうか
     */
    canEdit () {
      const tagNames = this.tags.map(tag => tag.name)
      return this.name.trim().length > 0 && !tagNames.includes(this.name)
    }
  },
  methods: {
    /**
     * ダイアログを開く
     * @return {Promise}
     */
    open () {
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    /**
     * ダイアログを閉じる
     */
    doClose () {
      this.resolve(false)
      this.dialog = false
    },
    /**
     * タグ名変更処理
     */
    async edit () {
      this.$store.commit('setSubmitting', true)

      const params = {
        name: this.name,
        updatedAt: new Date()
      }

      const promises =[]

      // タグ名の編集
      promises.push(this.$store.dispatch('tags/updateTag', { taid: this.tag.taid, params: params }))

      await Promise.all(promises)

      this.$store.commit('setTelop', { show: true, msg: 'タグ名を変更しました', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.edit {
  &__area {
    width: 100%;
    padding: 20px 15px;
    text-align: center;
    background-color: white;
    border-radius: 15px;
    &__title {
      display: block;
      margin: 0;
      &.v-card__title {
        padding: 0;
        font-size: 1.4rem;
        font-weight: bold;
        color: $black_color;
      }
    }
    &__msg {
      margin: 10px 0 0;
      text-align: left;
      &.v-card__text {
        padding: 0;
        font-size: 1.2rem;
        line-height: 1.7rem;
        color: $black_lighten_color;
      }
    }
    &__input {
      display: inline-block;
      width: 100%;
      padding: 10px 0;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $black_lighten_color;
      vertical-align: middle;
      border-bottom: 1px solid $black_color;
      &::placeholder {
        color: $gray_color;
      }
      &:focus {
        outline: none;
      }
    }
    &__btn {
      display: block;
      margin: 40px auto 0;
      font-size: 1.2rem;
      color: $white_color;
      border-radius: 5px;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: 78px;
        height: 34px;
        padding: 0 15px;
      }
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $orange_color;
      }
      &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        color: $white_color !important;
        background-color: $orange_lighten_color !important;
      }
    }
    &__close {
      margin: 10px 30px 0;
      font-size: 1.4rem;
      font-weight: bold;
      color: $gray_darken_color;
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
        height: auto;
        padding: 0;
      }
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';
// vuetify用
.v-dialog.edit {
  width: 85%;
  max-width: 400px;
  background-color: white;
  border-radius: 15px;
  box-shadow: none;
}

.v-overlay--active {
  .v-overlay__scrim {
    background-color: $black_lighten_color !important;
    border: none;
    opacity: 0.7 !important;
  }
}
</style>
