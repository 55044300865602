<template>
  <div class="tag">
    <div class="tag__name">
      <p class="tag__name__text">{{ tag.name }}</p>
    </div>
    <div class="tag__action">
      <v-btn class="tag__action__btn" depressed @click="$refs.edit.open()">
        <v-icon class="tag__action__btn__icon">edit</v-icon>
      </v-btn>
      <v-btn class="tag__action__btn" depressed @click="canDelete ? $refs.confirm.open() : $refs.warning.open()">
        <v-icon class="tag__action__btn__icon">delete</v-icon>
      </v-btn>
    </div>
    <tag-edit ref="edit" :tag="tag" />
    <confirm-dialog ref="confirm" title="タグの削除" :msg="'「' + tag.name + '」を削除します。'" @do-action="deleteTag()" />
    <warning-dialog ref="warning" title="タグの削除ができません" :msg="warningMsg" />
  </div>
</template>

<script>
import TagEdit from '@/components/exception/form/tag/edit'
import ConfirmDialog from '@/components/common/confirm'
import WarningDialog from '@/components/common/warning'

export default {
  components: { TagEdit, ConfirmDialog, WarningDialog },
  props: {
    // タグ情報
    tag: {
      type: Object,
      requred: true
    }
  },
  computed: {
    /**
     * @return {Object[]} 例外情報一覧
     */
    exemptions () {
      return this.$store.getters['exemptions/exemptions']
    },
    /**
     * @return {String} ワーニングダイアログに表示する文
     */
    warningMsg () {
      return '「' + this.tag.name + '」は使用中です。削除するには、使用しているユーザーのタグを変更する必要があります。'
    },
    /**
     * @return {Boolean} タグが削除可能かどうか
     */
    canDelete () {
      return !this.exemptions.find(exemption => exemption.taid === this.tag.taid)
    }
  },
  methods: {
    /**
     * タグの削除
     */
    async deleteTag () {
      this.$store.commit('setSubmitting', true)

      await this.$store.dispatch('tags/deleteTag', this.tag.taid)

      this.$store.commit('setTelop', { show: true, msg: 'タグを削除しました', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';
@import '@/assets/sass/fontfamily.scss';

.tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 $padding_width;
  &__name {
    display: flex;
    align-items: center;
    min-width: 60px;
    max-width: 100%;
    height: 34px;
    padding: 0 15px;
    border: 2px solid $orange_color;
    border-radius: 5px;
    &__text {
      margin: 0;
      overflow: hidden;
      font-size: 1.2rem;
      color: $orange_color;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: $white_color;
    }
  }
  &__action {
    min-width: 60px;
    margin-left: 10px;
    &__btn {
      transform: translateX(5px);
      &.v-btn:not(.v-btn--round).v-size--default {
        width: 30px;
        min-width: 0;
        height: 34px;
        padding: 0;
      }
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $white_color;
      }
      &__icon {
        font-family: $material-outlined;
        &.v-icon {
          font-size: 20px;
          color: $black_lighten_color;
        }
      }
    }
  }
}
</style>
